<template>
	<div class="main">
		<div class="tit"><span>安全设置 > 修改密码</span></div>
		
		<div class="form">
			<div class="li"><input type="password" v-model="old_pwd" class="input1" maxlength="16"  placeholder="请输入旧密码"></div>
			<div class="li"><input type="password" v-model="new_pwd" class="input1" maxlength="16"  placeholder="请输入不少于8位的密码，需包含字母数字的新密码"></div>
			<div class="li"><input type="password" v-model="new_pwd_confirm" class="input1" maxlength="16"  placeholder="重复一次新密码"></div>
			<a  class="btn1" @click="save">保存</a>
		</div>
		
	</div>
</template>
<script type="text/javascript">
	import icom from '@/common/com.js';
	import useStore from '@/store/index.js'
	export default {
		data(){
			return {
				new_pwd:'',
				old_pwd:'',
				new_pwd_confirm:'',
			}
		},
		created(){
			this.store = useStore()
		},
		components:{
		},
		methods:{
			save(){
				if(this.old_pwd==''){
					this.store.showToast('请输入旧密码')
					return
				}
				if(this.new_pwd==''){
					this.store.showToast('请输入新密码')
					return
				}
				if(!icom.checkStr(this.new_pwd,11)){
					this.store.showToast('请输入与大于8位小于16位并且包含字母数字的密码')
					return
				}
				
				if(this.new_pwd_confirm==''){
					this.store.showToast('请输入确认密码')
					return
				}
				if(this.new_pwd_confirm!=this.new_pwd){
					this.store.showToast('确认密码与新密码不一致')
					return
				}
				this.API.update_pwd({old_pwd:this.old_pwd,new_pwd:this.new_pwd,new_pwd_confirm:this.new_pwd_confirm},(res)=>{
					// 返回设置页面
					this.$router.push('/person/infoset')
				})
				
			},
			
			
		}
	}
</script>
<style type="text/css" scoped="">
	.main{flex: 1;background: #FFFFFF;border-radius: 4px;padding: 20px 25px;}
	.form {width: 500px;margin-left: 35px;margin-top: 4px;}
	.form .li {margin-top: 28px;}
	.form .input1 {width: 100%;height: 44px;border-radius: 6px;border: 1px solid #E3E6EC;padding: 0 26px;font-size: 16px;}
	.form .verifyCode {height: auto;overflow: hidden;}
	.form .verifyCode .input1 {width: 318px;float: left;}
	.form .verifyCode .btnSend {width: 143px;height: 44px;background: #F5630B;border-radius: 6px;float: right;;font-size: 16px;color: #fff;text-align: center;line-height: 44px;}
	.form .verifyCode .btnSend.disabled {background: #F3F3F6;color: #222;}
	.form .btn1 {width: 360px;height: 44px;background: #F5630B;border-radius: 6px;line-height: 44px;text-align: center;color: #fff;font-size: 18px;margin: 37px auto 0;display: block;}
</style>